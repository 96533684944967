<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      @dblclick:row="onClickedRow"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-spacer />
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-2"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.state.auth.user.isAdmin"
            dark color="accent" class="mb-2 ml-2"
            @click="$refs.editForm.open()"
          >
            <v-icon left>mdi-account-plus</v-icon>
            {{ $vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un utilisateur' }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.firstName`]="{ item }">
        {{ item.firstName }} {{ item.lastName }}
      </template>
      <template v-slot:[`item.roles`]="{ item }">
        <v-chip-group>
          <v-chip
            dark
            v-for="(role, index) in item.roles.filter(role => role !== 'ROLE_USER')"
            :color="colorForRole(role)"
            :key="index"
          >
            {{ role | prettyRoleName }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:[`item.company.name`]="{ item }">
        <span v-if="item.company">
          <router-link :to="{ name: 'CompanyUsers', params: { id: item.company.id } }">
            {{ item.company.name }}
          </router-link>
        </span>
        <span v-else>
          Aucune
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin && $store.state.debugMode"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="purple"
            >
              <v-icon
                small
                @click="$refs.resetPasswordBybassForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-link
              </v-icon>
            </v-btn>
          </template>
          <span>Générer un lien de réinitialisation de compte</span>
        </v-tooltip>
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
            >
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="error"
              :disabled="$store.state.auth.user.id === item.id"
            >
              <v-icon
                small
                @click="$refs.removeForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditUser
      ref="editForm"
      :default-company="company"
      :is-linked-to-company="isLinkedToCompany"
      @finished="fetchData"
    />
    <RemoveItem
      resource="users"
      title="cet utilisateur"
      ref="removeForm"
      @finished="fetchData"
    />
    <ResetPasswordBybassForm
      ref="resetPasswordBybassForm"
    />
  </div>
</template>

<script>
import ResetPasswordBybassForm from './ResetPasswordBybassForm.vue'
import EditUser from "./EditUser.vue"
import RemoveItem from "../RemoveItem.vue"
import roles from "../../utils/roles"

export default {
  components: {
    EditUser,
    RemoveItem,
    ResetPasswordBybassForm
  },
  props: {
    // to filter the list of users by the company they belong to
    company: {
      type: Object,
      default: null,
    },
    isLinkedToCompany: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableLoading: false,
      users: [],
      options: {

      },
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: 'Nom',
          value: 'firstName'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Rôles',
          value: 'roles'
        },
        ...(this.company ? [] : [{
          text: 'Entreprise',
          value: 'company.name'
        }]),
        {
          text: 'Créé le',
          value: 'createdAt'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        if (this.isLinkedToCompany && !this.company.id) {
          return
        }
        this.fetchData()
      },
      deep: true,
    },
    company: {
      handler () {
        this.fetchData()
      },
      deep: true,
    }
  },
  mounted () {
  },
  methods: {
    colorForRole (roleId) {
      return roles.getRole(roleId).color
    },
    fetchData () {
      if (this.tableLoading || (this.isLinkedToCompany && !this.company.id)) {
        return
      }

      this.tableLoading = true
      let action = null
      if (this.company) {
        action = this.$store.dispatch(
          'companies/fetchUsers',
          this.company,
          this.options
        )
      } else {
        action = this.$store.dispatch(
          'users/fetchAll',
          this.options
        )
      }
      action.then(res => {
        this.tableLoading = false
        this.users = res.member
        this.totalCount = res.totalItems
        console.log(this.users);
      }).catch((err) => {
        console.error(err)
        this.tableLoading = false
      })
    },
    onClickedRow (_, { item }) {
      if (!this.$store.state.auth.user.isAdmin) {
        return
      }
      this.$refs.editForm.open(item)
    },
  }
}
</script>
