var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.tableLoading,"options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.onClickedRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"outlined":"","small":"","icon":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.fetchData()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1),(_vm.$store.state.auth.user.isAdmin)?_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"dark":"","color":"accent"},on:{"click":function($event){return _vm.$refs.editForm.open()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un utilisateur')+" ")],1):_vm._e()],1)]},proxy:true},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',_vm._l((item.roles.filter(function (role) { return role !== 'ROLE_USER'; })),function(role,index){return _c('v-chip',{key:index,attrs:{"dark":"","color":_vm.colorForRole(role)}},[_vm._v(" "+_vm._s(_vm._f("prettyRoleName")(role))+" ")])}),1)]}},{key:"item.company.name",fn:function(ref){
var item = ref.item;
return [(item.company)?_c('span',[_c('router-link',{attrs:{"to":{ name: 'CompanyUsers', params: { id: item.company.id } }}},[_vm._v(" "+_vm._s(item.company.name)+" ")])],1):_c('span',[_vm._v(" Aucune ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAtObject))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.auth.user.isAdmin && _vm.$store.state.debugMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"purple"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.resetPasswordBybassForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-link ")])],1)]}}],null,true)},[_c('span',[_vm._v("Générer un lien de réinitialisation de compte")])]):_vm._e(),(_vm.$store.state.auth.user.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"info"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.editForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_vm._e(),(_vm.$store.state.auth.user.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"error","disabled":_vm.$store.state.auth.user.id === item.id}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.removeForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()]}}],null,true)}),_c('EditUser',{ref:"editForm",attrs:{"default-company":_vm.company,"is-linked-to-company":_vm.isLinkedToCompany},on:{"finished":_vm.fetchData}}),_c('RemoveItem',{ref:"removeForm",attrs:{"resource":"users","title":"cet utilisateur"},on:{"finished":_vm.fetchData}}),_c('ResetPasswordBybassForm',{ref:"resetPasswordBybassForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }