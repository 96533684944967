<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">Demandez un lien de réinitialisation de mot de passe</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <div class="d-flex justify-center mt-8">
          <div v-if="!link"  class="d-flex justify-center flex-column">
            <div class="d-flex justify-center">
            <v-btn
              class="mb-5"
              color="primary"
              :loading="loading"
              @click="ask()"
            >
              Demander
            </v-btn>
            </div>
            <p>Attention : les liens précédents seront invalidés !</p>
          </div>
          <div v-if="link">
            <a :href="link" target="_blank">{{ link }}</a>
            <p>
              Ce lien expirera à la date : {{ expiresAt | formatDate }}
            </p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rfdc from 'rfdc'

export default {
  props: {
  },
  computed: {
  },
  data: () => ({
    dialog: false,
    loading: false,
    user: { id: '' },
    expiresAt: null,
    link: ''
  }),
  created() {
  },
  methods: {
    open(item) {
      this.user = item

      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    ask() {
      this.loading = true
      this.$apitator.post(
        `/users/${this.user.id}/reset-password/request`,
        {}, { withAuth: true }
      ).then(res => {
        this.link = res.data.data.url
        this.expiresAt = res.data.data.resetToken.expiresAt
      }).catch(err => {
        console.error(err)
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Impossible de demander une réinitialisation à l'heure actuelle, veuillez reesayer plus tard"
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
