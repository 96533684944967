<template>
  <ListUsers
    :company="company"
    is-linked-to-company
  />
</template>

<script>
import ListUsers from '@/components/Users/ListUsers.vue'

export default {
  props: {
    company: {
      type: Object,
      default: null,
    },
  },
  components: { ListUsers }
}
</script>
